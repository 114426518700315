<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <!-- Category Information -->
    <b-row class="d-flex justify-content-center" style="margin-top: 90px">
      <b-col md="6">
        <b-card img-src="" img-alt="Profile Cover Photo" img-top class="card-profile">
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <CoolLightBox :items="iconArr" :index="imgIndex" @close="imgIndex = null;">
              </CoolLightBox>
              <b-img @click="imgIndex = 0" :src="categoryData.icon" class="rounded" size="120" variant="light" />
            </div>
          </div>
          <h2><span style="color: #0d6efd"></span></h2>
          <h3></h3>
          <h6 class="text-muted"></h6>
          <b-badge class="profile-badge" variant="light-primary">
            <span v-for="(translation, index) in categoryData.translation" style="font-size: 25px; margin: auto"
              :key="index">
              {{ translation.name }}
              <span v-if="
                categoryData.translation.length > 1 &&
                index < categoryData.translation.length - 1
              ">-</span>
            </span>
          </b-badge>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
          </div>
          <hr class="mb-2" />
          <h5>
            code:
            <b-badge variant="primary">{{ categoryData.code }}</b-badge>
          </h5>
          <h5>
            Weight:
            <b-badge variant="primary">{{ categoryData.weight }}</b-badge>
          </h5>
          <hr class="mb-2" />
          <h5>
            Status:
            <b-badge variant="primary">{{ categoryData.active }}</b-badge>
          </h5>
          <h5>
            Selling Channel:
            <b-badge class="mx-1" variant="success" v-if="categoryData.is_b2c_sell">{{ 'B2C' }}</b-badge>
            <b-badge variant="success" v-if="categoryData.is_c2c_sell">{{ 'C2C' }}</b-badge>
          </h5>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <b-modal id="modal-deactive" cancel-variant="outline-secondary" @ok="deActiveCategory(categoryData.id)"
            ok-title="Ok" cancel-title="Close" centered title="Deactivate Category">
            <b-form>
              <b-form-checkbox id="checkbox-1" v-model="deactive" name="checkbox-1" value="1" unchecked-value="0">
                Force Deactivate
              </b-form-checkbox>
            </b-form>
          </b-modal>
          <b-modal id="modal-delete" cancel-variant="outline-secondary" @ok="deleteData(categoryData.id)" ok-title="Ok"
            cancel-title="Close" centered title="Delete Category">
            <b-form>
              <b-form-checkbox id="checkbox-2" v-model="deletedCategory" name="checkbox-2" value="1"
                unchecked-value="0">
                Force Delete
              </b-form-checkbox>
            </b-form>
          </b-modal>
          <b-modal id="modal-active" cancel-variant="outline-secondary" @ok="activeCategory(categoryData.id)"
            ok-title="Ok" cancel-title="Close" centered title="Activate Category">
            <b-form style="max-height: 500px; overflow-x: hidden; overflow-y: auto">
              <b-form-group label="Using options array:" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group id="checkbox-group-1" stacked v-model="restoreSelected.items" :options="options"
                  :aria-describedby="ariaDescribedby" name="flavour-1"></b-form-checkbox-group>
              </b-form-group>
              <infinite-loading @infinite="getAllVariantsInSystem"></infinite-loading>
            </b-form>
          </b-modal>
          <div v-show="delCat == true">
            <b-button block v-if="authPermissions.includes('update')"
              :to="{ path: '/Update-Category/' + categoryData.id }" variant="primary">
              Update
            </b-button>
            <b-button block v-b-modal.modal-active v-if="authPermissions.includes('update')"
              v-show="categoryData.active == false" @click="restoreSelected.items = []" variant="info">
              Active
            </b-button>
            <b-button block v-b-modal.modal-deactive v-if="authPermissions.includes('update')"
              v-show="categoryData.active == true" variant="info">
              Deactive
            </b-button>
            <b-button v-b-modal.modal-delete block v-if="authPermissions.includes('delete')" variant="outline-danger"
              class="">
              Delete
            </b-button>
          </div>
          <div class="d-flex justify-content-center">
            <b-modal id="modal-restore" cancel-variant="outline-secondary" @ok="restoreCat(categoryData.id)"
              ok-title="Ok" cancel-title="Close" centered title="Restore Category">
              <b-form style="max-height: 500px; overflow-x: hidden; overflow-y: auto">
                <b-form-group label="Using options array:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group id="checkbox-group-1" stacked v-model="restoreSelected.items"
                    :options="options" :aria-describedby="ariaDescribedby" name="flavour-1"></b-form-checkbox-group>
                </b-form-group>
                <infinite-loading @infinite="getAllVariantsInSystem"></infinite-loading>
              </b-form>
            </b-modal>
            <b-button v-show="delCat == false" v-b-modal.modal-restore block v-if="authPermissions.includes('update')"
              @click="restoreSelected.items = []" variant="outline-danger">
              Restore
            </b-button>
          </div>
          <hr />
          <div v-if="delCat" class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            ">
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ categoryData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ categoryData.updated_at }}
              </h3>
            </div>
          </div>
          <div v-else class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            ">
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ categoryData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Deleted at</h6>
              <h3 class="mb-0">
                {{ categoryData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Cover">
          <div class="d-flex justify-content-center">
            <CoolLightBox :items="coverArr" :index="imgIndex1" @close="imgIndex1 = null">
            </CoolLightBox>
            <b-img @click="imgIndex1 = 0" rounded :src="categoryData.cover" alt="Cover" class=""
              style="max-width: 100%; height: auto" />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Description">
      <!-- Category Translations -->
      <b-card-body>
        <b-tabs align="center" class="text-center">
          <b-tab v-for="(translation, index) in categoryData.translation"
            :title="translation.locale == 'en' ? 'English' : 'Arabic'" :key="index">
            <b-card-text v-html="translation.description"></b-card-text>
            <b-button v-if="authPermissions.includes('update') && delCat" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm" variant="warning" class="btn-cart" :to="{
                name: 'Category-Update-Trans',
                params: { id: categoryData.id, language: translation.locale },
              }">
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span>Update {{ translation.locale }} translation text</span>
            </b-button>
            <b-button v-if="
              authPermissions.includes('delete') &&
              translation.locale != 'en' &&
              translation.locale != 'ar' &&
              delCat
            " v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="danger" class="btn-cart ml-1"
              @click="deleteTranslation(translation.id)">
              <feather-icon icon="DeleteIcon" class="mr-50" />
              <span>Delete {{ translation.locale }} translation text</span>
            </b-button>
          </b-tab>
          <!-- add new translation -->
          <b-button v-if="authPermissions.includes('create')" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm"
            variant="success" block class="btn-cart mt-3"
            :to="{ path: '/category/add/description/' + categoryData.id }">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Add New Translation To Text</span>
          </b-button>
        </b-tabs>
      </b-card-body>
    </b-card>



    <b-card title="Attributes " v-if="categoryData.attributes != null">
      <div class="d-flex justify-content-end mb-1">

        <b-button v-if="authPermissions.includes('update') && delCat" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm" variant="warning" class="btn-cart" :to="{
                  name: 'Manage-Category-Attributes',
                  params: { id: categoryData.id },
                }">
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span>Manage Attributes</span>
              </b-button>
      </div>
    
            
        <div class="table-responsive">
          <table class="table text-left">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Sort</th>
                <th scope="col">Is Include Name</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in categoryData.attributes" class="mb-2 mt-2" :key="index">
                <th scope="row">{{ item.name }}</th>
                <td> {{ item.pivot.sort }}</td>
                <td>

                  <p v-if="item.pivot.is_include_name">
                    <span>
                      <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                    </span>
                  </p>

                </td>

              </tr>
            </tbody>
          </table>
      </div>
    </b-card>
  </section>
</template>

<script>
// import axios from '@/libs/axios'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      id: 0,
      deactive: '0',
      CategoryID: null,
      deletedCategory: '0',
      categoryData: {},
      errors_back: [],
      showDismissibleAlert: false,
      authPermissions: null,
      imgIndex: null,
      imgIndex1: null,
      iconArr: [],
      coverArr: [],
      delCat: true,
      restoreSelected: {
        items: [],
      },
      options: [],
      currentPage: 0,
    }
  },
  mounted() {
    // rquest category data
  },
  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('categories/' + this.id)
        .then((result) => {
          this.categoryData = result.data.data
          this.iconArr.push(this.categoryData.icon)
          this.coverArr.push(this.categoryData.cover)
          if (this.categoryData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    getAllVariantsInSystem($state) {
      this.currentPage += 1
      //console.log("hello")
      axios
        .get(
          'categories/' +
          this.categoryData.id +
          '/items-pagi/10?page=' +
          this.currentPage
        )
        .then((result) => {
          //console.log(result)
          const items = result.data.data.data
          this.loadMore = false
          if (items.length > 0) {
            for (let index in items) {
              if (items[index].item_translations != null) {
                this.options.push({
                  text: items[index].item_translations.name,
                  value: items[index].id.toString(),
                })
              } else {
                this.options.push({
                  text: 'undefinded',
                  value: items[index].id.toString(),
                })
              }
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    activeCategory(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to activate category.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .post(
                'categories/' + id + '/active-category',
                this.restoreSelected
              )
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deActiveCategory(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to deactivate category.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('categories/' + id + '/deactive-category/' + this.deactive)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    // delete function to delete category
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete category.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete(
                'categories/' + id + '/archive-category/' + this.deletedCategory
              )
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreCat(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore category.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('categories/' + id + '/restore')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteTranslation(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete translation.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('categories-translations/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
